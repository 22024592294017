<template>
  <div class="leftpanel-root-container">
    <button type="button" v-on:mouseover="onAboutClick" v-on:click="onAboutClick" v-bind:class="[this.appStateStore.aboutPage ? 'selectedFreeButton' : 'notSelectedFreeButton' ]">O nas</button>
    <div class="btGroup">
      <button type="button" v-on:mouseover="onOfferClick" v-on:click="onOfferClick" v-bind:class="[getOfferPage ? 'selectedButton' : 'notSelectedButton' ]">Co oferujemy</button>
      <div v-show="appStateStore.getOfferPage" class="btSubGroup">
        <button  type="button" v-show="appStateStore.getOfferPage" v-on:mouseover="onSystemDesignClick" v-on:click="onSystemDesignClick" v-bind:class="[this.appStateStore.systemDesign ? 'selectedSubButton' : 'notSelectedSubButton' ]">Projektowanie systemów</button>
        <button type="button" v-show="appStateStore.getOfferPage" v-on:mouseover="onSoftwareOnDemandClick" v-on:click="onSoftwareOnDemandClick" v-bind:class="[this.appStateStore.softwareOnDemand ? 'selectedSubButton' : 'notSelectedSubButton' ]">Dedykowane oprogramowanie</button>
        <button type="button" v-show="appStateStore.getOfferPage" v-on:mouseover="onProjectManagingClick" v-on:click="onProjectManagingClick" v-bind:class="[this.appStateStore.projectManaging ? 'selectedSubButton' : 'notSelectedSubButton' ]">Prowadzenie i zarządzanie projektami IT</button>
        <button type="button" v-show="appStateStore.getOfferPage" v-on:mouseover="onItConsultingClick" v-on:click="onItConsultingClick" v-bind:class="[this.appStateStore.itConsulting ? 'selectedSubButton' : 'notSelectedSubButton' ]">Doradztwo informatyczne</button>
      </div>
    </div>
    <button type="button" v-on:mouseover="onContactClick" v-on:click="onContactClick" v-bind:class="[this.appStateStore.contactPage ? 'selectedFreeButton' : 'notSelectedFreeButton' ]">Kontakt</button>
  </div>
</template>

<script>
import {useAppStateStore} from "@/store/modules/appState";

export default {
  name: "LeftPanel",
  setup() {
    const appStateStore=useAppStateStore();

    return {appStateStore }
  },
  computed:{
    getOfferPage(){
      return this.appStateStore.offerPage;
    }
  },
  methods:{
    onOfferClick(){
      this.appStateStore.aboutPage=false;
      this.appStateStore.offerPage=true;
      this.appStateStore.contactPage=false;
      this.onSystemDesignClick();
    },
    onSystemDesignClick(){
      this.appStateStore.systemDesign=true;
      this.appStateStore.softwareOnDemand=false;
      this.appStateStore.projectManaging=false;
      this.appStateStore.itConsulting=false;
      this.$router.push('/systems');
    },
    onSoftwareOnDemandClick(){
      this.appStateStore.systemDesign=false;
      this.appStateStore.softwareOnDemand=true;
      this.appStateStore.projectManaging=false;
      this.appStateStore.itConsulting=false;
      this.$router.push('/software');
    },
    onProjectManagingClick(){
      this.appStateStore.systemDesign=false;
      this.appStateStore.softwareOnDemand=false;
      this.appStateStore.projectManaging=true;
      this.appStateStore.itConsulting=false;
      this.$router.push('/managing');
    },
    onItConsultingClick(){
      this.appStateStore.systemDesign=false;
      this.appStateStore.softwareOnDemand=false;
      this.appStateStore.projectManaging=false;
      this.appStateStore.itConsulting=true;
      this.$router.push('/consulting');
    }
    ,
    onAboutClick(){
      this.appStateStore.aboutPage=true;
      this.appStateStore.offerPage=false;
      this.appStateStore.contactPage=false;
      this.$router.push('/');
    },
    onContactClick(){
      this.appStateStore.aboutPage=false;
      this.appStateStore.offerPage=false;
      this.appStateStore.contactPage=true;
      this.$router.push('/contact');
    }
  }

}
</script>

<style scoped>
 *{
   box-sizing:border-box;
   margin: 0;
   padding: 0;
 }

  .leftpanel-root-container{
    background-color: paleturquoise;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height:100%;
  }

  .btGroup{
    background-color: yellow;
    flex-grow: 1;
    height: 34%;

    border-color: darkblue;
    border-radius: 5px;

    display:flex;
    flex-direction: row;
    justify-content: space-between;

  }

  .btSubGroup{
    background-color: blueviolet;
    border-color: darkblue;
    border-radius: 5px;
    flex-grow: 1;
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .selectedButton{
    background-color: blueviolet;
    border-width: 3px;
    border-color: darkblue;
    border-radius: 5px;
    width:40%;

    flex-grow: 1;
  }

  .notSelectedButton{
    background-color: aqua;
    border-width: 3px;
    border-color: darkblue;
    border-radius: 5px;
    width:100%;
  }

  .selectedFreeButton{
    background-color: blueviolet;
    flex-grow: 1;
    height: 33%;
    border-width: 3px;
    border-color: darkblue;
    border-radius: 5px;
  }

  .notSelectedFreeButton{
    background-color: aqua;
    flex-grow: 1;
    height: 33%;
    border-width: 1px;
    border-radius: 5px;
  }

  .selectedSubButton{
    background-color: darkcyan;
    flex-grow: 1;
    border-width: 3px;
    border-radius: 5px;
  }

  .notSelectedSubButton{
    background-color: aqua;
    flex-grow: 1;
    border-width: 1px;
    border-radius: 5px;
  }



</style>