import { createApp } from 'vue';
import App from './App.vue';
import {pinia} from './store';
import {createWebHistory,createRouter} from "vue-router";
import {i18n} from "./i18n/i18n";
import FlagIcon from 'vue-flag-icon';
import LeftPanel from "@/components/LeftPanel";
import BottomSpace from "@/components/BottomSpace";
import WelcomePage from "@/components/subcomponents/WelcomePage";
import TopBar from "@/components/TopBar";
import SystemDesign from "@/components/subcomponents/SystemDesign";
import DedicatedSoftware from "@/components/subcomponents/DedicatedSoftware";
import ProjectManagment from "@/components/subcomponents/ProjectManagement";
import ContactDetails from "@/components/subcomponents/ContactDetails";
import ConsultingServices from "@/components/subcomponents/ConsultingServices";


const app=createApp(App);
app.use(pinia);
app.use(i18n);
app.use(FlagIcon);

const router= createRouter({
    history:createWebHistory(),
    routes: [
        {path:'/',components: {
                MainSpaceHolder: WelcomePage,
                TopBarHolder: TopBar,
                LeftBarHolder: LeftPanel,
                BottomSpaceHolder: BottomSpace
            }
        },
        {
            path:'/systems',components:{
                MainSpaceHolder: SystemDesign,
                TopBarHolder: TopBar,
                LeftBarHolder: LeftPanel,
                BottomSpaceHolder: BottomSpace
            }
        },
        {
            path:'/software',components:{
                MainSpaceHolder: DedicatedSoftware,
                TopBarHolder: TopBar,
                LeftBarHolder: LeftPanel,
                BottomSpaceHolder: BottomSpace
            }
        },
        {
            path:'/managing',components:{
                MainSpaceHolder: ProjectManagment,
                TopBarHolder: TopBar,
                LeftBarHolder: LeftPanel,
                BottomSpaceHolder: BottomSpace
            }
        },
        {
            path:'/consulting',components:{
                MainSpaceHolder: ConsultingServices,
                TopBarHolder: TopBar,
                LeftBarHolder: LeftPanel,
                BottomSpaceHolder: BottomSpace
            }
        },
        {
            path:'/contact',components:{
                MainSpaceHolder: ContactDetails,
                TopBarHolder: TopBar,
                LeftBarHolder: LeftPanel,
                BottomSpaceHolder: BottomSpace
            }
        }
    ]
});
app.use(router);


app.mount('#app');
