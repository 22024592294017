import {createI18n} from "vue-i18n";


const messages = {
    'pl': {
        welcomeMsg: 'Witamy!!!'
    },
    'en': {
        welcomeMsg: 'Welcome!!!'
    }
};

export const i18n=new createI18n({
    locale: 'pl', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages // set locale messages
});