<template>
  <div class="default">
    Welcome Page

    <h1>UNDER CONSTRUCTION</h1>
    <h1>{{ $t('welcomeMsg') }}</h1>
    <img alt="Vue logo" src="@/assets/logo.png">
    <button>test</button>



  </div>
</template>

<script>
export default {
  name: "WelcomePage"
}
</script>

<style scoped>
  .default{
    background-color: beige;
  }

</style>