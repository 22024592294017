import { defineStore } from 'pinia';


export const useUserStore = defineStore(
    {
        id: 'user',
        state: () => ({
            login: 'unknown'
        }),
        getters:{
            getLogin(state){
                return state.login;
            }
        }
    }
)

