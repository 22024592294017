<template>
  <div class="root-container">
    <div class="toppanel">
      <router-view name="TopBarHolder"></router-view>
    </div>
    <div class="midsection">
      <div class="leftpanel">
        <router-view name="LeftBarHolder"></router-view>
      </div>
      <div class="mainsecion">
        <router-view name="MainSpaceHolder"></router-view>
      </div>
    </div>
    <div class="bottompanel">
      <router-view name="BottomSpaceHolder"></router-view>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/store/modules/user";


export default {
  name: 'App',
  setup() {
    const userStore = useUserStore();

    return {userStore}
  },
  data() {
    return {
      myName: "Łukasz"
    }
  },
  methods: {},
  components: {},
  computed: {
    getUser() {
      return this.userStore.login;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.root-container {
  background-color: darkcyan;
  height:100vh;


  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.midsection {
  width: 100%;
  flex-grow: 1;
  background-color: darkblue;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.toppanel {
  flex-grow: 0;
  width: 100%;
  height: auto;
  background: coral;
}

.bottompanel {
  flex-grow: 0;
  width: 100%;
  height: auto;
  background: coral;
}

.leftpanel {
  flex-grow: 2;
  background-color: green;
  width: 15%;
}

.mainsecion {
  flex-grow: 8;
  height: 100%;
  width: 85%;
  background-color: yellow;
}


</style>
