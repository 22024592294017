<template>
  <div class="default">
    BottomSpace
  </div>
</template>

<script>
export default {
  name: "BottomSpace"
}
</script>

<style scoped>
.default{
  background-color: blueviolet;
}
</style>