<template>
  <div class="locale-switcher-root-container">
    <button class="flag-button" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)" >
      <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
    </button>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data(){
    return {
      languages: [
        { flag: 'gb', language: 'en', title: 'Eng' },
        { flag: 'pl', language: 'pl', title: 'Pl' }
      ]
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
}
</script>

<style scoped>
.locale-switcher-root-container{
  display: flex;
  flex-direction: row;
}

.flag-button{
  margin: 1px;
}
</style>