import { defineStore } from 'pinia';


export const useAppStateStore = defineStore(
    {
        id: 'appState',
        state: () => ({
            aboutPage: true,
            contactPage:false,
            offerPage:false,
            systemDesign:true,
            softwareOnDemand:false,
            projectManaging:false,
            itConsulting:false
        }),
        getters:{
            getAboutPage(state){
                return state.aboutPage;
            },
            getContactPage(state){
                return state.contactPage;
            },
            getOfferPage(state){
                return state.offerPage;
            }
        }
    }
)

