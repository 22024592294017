<template>
  <div>
    <!--<img src="@/assets/system_design_diagram.svg"/>-->
    <img src="@/assets/project_phases_diagram.svg"/>
  </div>
</template>

<script>
export default {
  name: "SystemDesign"
}
</script>

<style scoped>

</style>