<template>
  <div class="topbar-root-container">
    <div class="user-area">
      <h3>User here: {{userStore.login}} or maybe {{myName}} or {{getUser}}</h3>
    </div>
    <div class="locale-switcher-area">
      <LocaleSwitcher/>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store/modules/user";
import LocaleSwitcher from "@/components/LocaleSwitcher";
export default {
  name: "TopBar",
  setup() {
    const userStore = useUserStore();

    return { userStore }
  },
  components:{
    LocaleSwitcher
  }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.topbar-root-container{
  background-color: burlywood;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.user-area{
  margin: 1px;
  margin-right: 5px;
}

.locale-switcher-area{
  margin: 1px;
  margin-right: 5px;
}

</style>